<template>
    <div class="datas">
        <div class="datas-info">
            <div class="userTitle">
                <span>{{ $t('shu-ju-gai-kuang') }}</span>

                <el-radio-group v-model="type" size="mini">
                    <el-radio-button v-for="item in dayTypes" :key="item.value" :label="item.value">
                        {{ item.title }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <el-divider></el-divider>
            <el-row>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <div class="text1">{{ $t('zhan-ting-fang-ke-ren-shu') }}(UV)</div>
                        <div class="text2">
                            <ICountUp ref="uv" :endVal="125" :options="options" />
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <div class="text1">{{ $t('zhan-ting-liu-lan-liang') }}(PV)</div>
                        <div class="text2">
                            <ICountUp ref="pv" :endVal="249" :options="options" />
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <div class="text1">{{ $t('zhan-ting-fang-wen-ci-shu') }}(VV)</div>
                        <div class="text2">
                            <ICountUp ref="vv" :endVal="168" :options="options" />
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <div class="text1">{{ $t('zi-xun-ren-shu') }}</div>
                        <div class="text2">
                            <ICountUp ref="advisory" :endVal="40" :options="options" />
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="datas-info">
            <div class="userTitle">
                <div>
                    <span>{{ $t('shu-ju-qu-shi') }}</span>
                    <el-select size="mini" v-model="moveType">
                        <el-option v-for="item in datasType" :key="item.value" :label="item.title" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-button
                        size="mini"
                        icon="el-icon-upload2"
                        class="downLoad"
                        style="margin-left: 10px"
                        :loading="downloading"
                        @click="downloadData"
                        >{{ $t('dao-chu') }}</el-button
                    >
                </div>

                <el-radio-group v-model="type2" size="mini">
                    <el-radio-button v-for="item in dayTypes2" :key="item.value" :label="item.value">
                        {{ item.title }}
                    </el-radio-button>
                </el-radio-group>
            </div>

            <div class="chart">
                <LineChart :styles="chartStyles" ref="chart" :chartData="chartdata" :options="chartoptions"></LineChart>
            </div>
        </div>

        <div class="datas-info product-datas">
            <div class="userTitle">
                <div>
                    <div>
                        {{ $t('chan-pin-shu-ju') }}
                    </div>
                    <div class="tips" v-if="isChinese">
                        {{ $t('dang-qian-jie-guo-gong') }} {{ productList.length }} {{ $t('ge-chan-pin') }}
                    </div>
                    <div class="tips" v-else>Current: {{ productList.length }} result(s)</div>
                </div>

                <el-button
                    size="mini"
                    icon="el-icon-upload2"
                    class="downLoad"
                    :loading="downloading"
                    @click="downloadProduct"
                    >{{ $t('dao-chu') }}</el-button
                >

                <div class="flex1"></div>

                <el-input
                    size="mini"
                    :placeholder="$t('sou-suo')"
                    class="search-icon"
                    v-model="search"
                    @change="submitSearch"
                    clearable
                >
                </el-input>

                <el-radio-group v-model="type3" size="mini">
                    <el-radio-button v-for="item in dayTypes2" :key="item.value" :label="item.value">
                        {{ item.title }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <el-divider></el-divider>

            <div class="product">
                <el-table
                    :data="productList"
                    v-loading="loading3"
                    highlight-current-row
                    size="mini"
                    row-key="id"
                    height="382"
                >
                    <el-table-column prop="mainPicture" :label="$t('chan-pin-tu-pian')" min-width="70" align="center">
                        <template slot-scope="{ row }">
                            <el-image
                                style="width: 50px; height: 50px"
                                :src="getMain(splImg(row.mainPicture))"
                                fit="cover"
                                :preview-src-list="splImg(row.mainPicture)"
                            ></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="chName"
                        show-overflow-tooltip
                        :label="$t('chan-pin-ming-cheng')"
                        min-width="130"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="enName"
                        show-overflow-tooltip
                        :label="$t('chan-pin-xing-hao')"
                        min-width="110"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="addedTime"
                        sortable
                        sort-by="addedTime"
                        :label="$t('shang-jia-shi-jian')"
                        min-width="100"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="uv"
                        sortable
                        :label="$t('fang-ke-ren-shu')"
                        sort-by="uv"
                        min-width="100"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="pv"
                        sortable
                        :label="$t('liu-lan-liang')"
                        sort-by="pv"
                        min-width="100"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="vv"
                        sortable
                        :label="$t('fang-wen-ci-shu')"
                        sort-by="vv"
                        min-width="100"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="advisory"
                        sortable
                        :label="$t('zi-xun-ren-shu')"
                        sort-by="advisory"
                        min-width="100"
                        align="center"
                        F
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { dayType, datasType } from '../../utils/AppState';
import ICountUp from 'vue-countup-v2';
import LineChart from '../../components/LineChart';
import { format, parse, addDays } from 'date-fns';
export default {
    name: 'datas',
    data() {
        return {
            type: 3,
            type2: 7,
            type3: 30,
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            moveType: 'uv',
            chartdata: {},
            chartStyles: {
                position: 'relative',
                wdith: '100%',
                height: '100%'
            },
            chartList: [],
            productList: [],
            searchProduct: '',
            loading3: false,
            search: '',

            downloading: false
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        dayTypes() {
            return [...dayType].map(item => {
                return {
                    value: item[0],
                    title: this.$t(item[1])
                };
            });
        },
        datasType() {
            return [...datasType].map(item => {
                return {
                    value: item[0],
                    title: this.$t(item[1].name) + (item[1].addKey ? `(${item[0]})` : '')
                };
            });
        },
        dayTypes2() {
            return [
                {
                    value: 7,
                    title: this.$t('jin-qi-tian')
                },
                {
                    value: 30,
                    title: this.$t('jin-san-shi-tian')
                }
            ];
        },
        chartoptions() {
            var line = [1];
            for (let i = 0; i < 30; i++) {
                line.push(0);
            }
            return {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            },
                            type: 'linear'
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                lineWidth: line
                            }
                        }
                    ]
                },
                legend: {
                    display: false
                }
            };
        }
    },
    watch: {
        type() {
            this.getDatas();
        },
        type2() {
            this.getChart();
        },
        type3() {
            this.getProduct();
        },
        moveType() {
            this.drawChart();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getDatas();
            this.getChart();
            this.getProduct();
        });
    },
    methods: {
        getProduct() {
            this.loading3 = true;
            this.$http
                .post('/statistics/productList?vendorId=' + this.buyersInfo.vendorId + '&day=' + this.type3, {
                    search: this.searchProduct,
                    sortType: 'addedTime,asc'
                })
                .then(res => {
                    this.productList = res;

                    this.loading3 = false;
                })
                .catch(e => {
                    this.productList = [];

                    this.loading3 = false;
                });
        },
        getDatas() {
            this.$http
                .post('/statistics/vendor?vendorId=' + this.buyersInfo.vendorId + '&type=' + this.type)
                .then(res => {
                    this.$refs.pv.update(res.pv);
                    this.$refs.uv.update(res.uv);
                    this.$refs.vv.update(res.vv);
                    this.$refs.advisory.update(res.advisory);
                });
        },
        getChart() {
            this.$http
                .post('/statistics/vendorList?vendorId=' + this.buyersInfo.vendorId + '&day=' + this.type2)
                .then(res => {
                    this.chartList = res.sort((a, b) => {
                        return parse(b.date, 'yyyy-MM-dd', new Date()) - parse(a.date, 'yyyy-MM-dd', new Date());
                    });
                    this.drawChart();
                });
        },
        drawChart() {
            this.$nextTick(() => {
                this.chartdata = {
                    labels: [...this.chartList].map(item => {
                        return item.date;
                    }),
                    datasets: [
                        {
                            label: this.$t('ren-ci'),
                            backgroundColor: 'rgb(54, 162, 235)',
                            borderColor: 'rgb(54, 162, 235)',
                            fill: false,
                            data: [...this.chartList].map(item => {
                                return item[this.moveType];
                            })
                        }
                    ]
                };
            });
        },
        splImg(info) {
            // console.log(info);
            return info ? info.split(',') : [];
        },
        getMain(list) {
            if (list.length > 0) {
                return list[0] + '?x-oss-process=image/resize,m_fill,h_100,w_100';
            } else {
                return '';
            }
        },
        submitSearch() {
            this.searchProduct = this.search;
            this.getProduct();
        },
        downloadData() {
            this.downloading = true;
            this.$axios
                .get('/statistics/excelVendor', {
                    responseType: 'blob',
                    params: {
                        vendorId: this.buyersInfo.vendorId,
                        day: this.type2
                    }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        downloadProduct() {
            this.downloading = true;
            this.$axios
                .get('/statistics/excelProduct', {
                    responseType: 'blob',
                    params: {
                        vendorId: this.buyersInfo.vendorId,
                        day: this.type3
                    }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        }
    },
    components: {
        ICountUp,
        LineChart
    }
};
</script>
<style lang="scss" scoped>
.datas {
    background-color: transparent !important;
    overflow: hidden;
}

.datas-info {
    background-color: #fff;
}

.datas-info + .datas-info {
    margin-top: 10px;
}
.product-datas {
    .userTitle {
        height: 82px;
    }
}
.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    line-height: 22px;
    .tips {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 2px;
    }
}

.el-col {
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-content {
    padding: 0.4rem 0 0.38rem;
    min-width: 120px;
    .text1 {
        font-size: 0.14rem;
        color: #878d99;
        line-height: 0.2rem;
    }

    .text2 {
        font-size: 0.48rem;
        color: #292c33;
        font-family: OSP-DIN, OSP;
        line-height: 55px;
        margin-top: 0.1rem;
    }
}

.el-select {
    margin-left: 40px;
}

.chart {
    padding: 20px;
}
.el-divider--horizontal {
    margin: 0 auto;
    width: 97%;
}

.product {
    padding: 0 20px;
}

.downLoad {
    margin-left: 40px;
    color: #565b66;
}

.search-icon {
    margin-right: 20px;
}
</style>
<style lang="scss">
.datas-info {
    .el-radio-group {
        .el-radio-button__inner {
            border-radius: 0px !important;
            color: #ffa526;
            border-color: #ffa526;
        }
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            background-color: #ffa526;
            border-color: #ffa526;
            box-shadow: -1px 0 0 0 #ffa526;
            color: #fff;
        }

        .el-radio-button:first-child .el-radio-button__inner {
            border-left-color: #ffa526;
        }
    }

    .el-select-dropdown__item.selected {
        font-weight: normal;
        color: #ffa526;
    }
}

.el-table__body-wrapper {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #333;
    }
}

.product {
    .el-table {
        .sort-caret {
            border: solid 4px transparent;
            &.ascending {
                border-bottom-color: #565b66;
                top: 7px;
            }

            &.descending {
                border-top-color: #565b66;
                bottom: 9px;
            }
        }
        .ascending > .cell,
        .descending > .cell {
            color: #ffa526;
        }

        .ascending .sort-caret.ascending {
            border-bottom-color: #ffa526;
        }

        .descending .sort-caret.descending {
            border-top-color: #ffa526;
        }

        .el-table th > .cell {
            color: #878d99;
        }
    }
}

.search-icon {
    width: 240px;
    &.el-input--suffix {
        .el-input__inner {
            padding-right: 50px;
            background-color: #f2f3f5;
            border-color: #f2f3f5;
            &:hover,
            &:focus {
                border-color: #ffa526;
            }
        }
        .el-input__suffix {
            right: 25px;
        }

        &::after {
            content: '\e603';
            font-family: 'iconfont';
            font-size: 16px;
            font-weight: normal;
            position: absolute;
            height: 100%;
            top: 0;
            text-align: center;
            right: 10px;
            line-height: 28px;
            color: #bcc1cc;
            cursor: pointer;
            &:hover {
                color: #ffa526;
            }
        }
    }
}
</style>
